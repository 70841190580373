import { Calendar } from 'components';
import { connect } from 'react-redux';
import actions from 'actions';


export default connect (
	({ points: { date }, table }) => ({ pointsDate: date, table }),
	dispatch => ({
		pointsSetDate: ( date, closeTable ) => dispatch ( actions.pointsSetDate ( date, true ) ),
		tableSetDate: ( date, closed ) => dispatch ( actions.tableSetDate ( date, closed ) )
	})
)( Calendar );
