import { all, put, takeLatest } from 'redux-saga/effects';
import * as actionTypes from 'actionTypes';
import actions from 'actions';
import api from 'api';


export default function* root ()
{
	yield all ([
		takeLatest ( actionTypes.AUTH_REQUEST, authRequest ),
		takeLatest ( actionTypes.CONFIRM_REQUEST, confirmRequest ),
		takeLatest ( actionTypes.LOGIN, login ),
		takeLatest ( actionTypes.LOGOUT, logout )
	]);
}

export function* authRequest ({ payload: { phone } })
{
	phone = phone.replace ( /\D/g, '' ).slice ( -10 );

	const storedDevice = api.storage.get ( 'device' ),
		{ error, device } = yield api.web.auth ( phone, storedDevice );

	if ( !error && device !== storedDevice ) api.storage.set ( 'device', device );

	yield put ( actions.auth ( error ) );
}

export function* confirmRequest ({ payload: { code } })
{
	code = code.replace ( /\D/g, '' ).slice ( -4 );

	const device = api.storage.get ( 'device' ),
		{ error, token } = yield api.web.confirm ( code, device );

	if ( !error )
	{
		api.storage.set ( 'token', token );

		yield put ( actions.login ( token ) );
	}

	yield put ( actions.confirm ( error ) );
}

export function* login ({ payload: { token } })
{
	api.web.setToken ( token );

	yield put ( actions.getGroups() );
	yield put ( actions.initFiltersRequest() )
}

export function logout ()
{
	api.web.setToken ( false );
	api.storage.remove ( 'token' );
}
