import React, { useMemo } from 'react';
import { makeStyles } from '@material-ui/core';
import TableTable from './TableTable';
import Header from './Header';
import { SideButton } from 'mlk-ui-kit';
import { Waiter } from 'components';
import clsx from 'clsx';


export default React.memo ( Table );

function Table ({
	className,
	isRange,
	closed,
	isGroup,
	groupId,
	tableType,
	id,
	name,
	description,
	visits = {},
	workingTime,
	workingSeconds,
	visitSeconds,
	totalVisitSeconds,
	dn,
	clientCount,
	clients = {},
	sum,
	pdz,
	tasks = {},
	photo,
	rows = [],
	ordersCount,
	orders,
	managerId,
	ordersPointCount,
	ordersPoint,
	ordersPhone,
	orders1c,
	request,
	waiter,
	pointsWaiter,
	tablePointControllerWaiter,
	panel,
	closeTable
})
{
	const classes = useStyles ({ panel });

	rows = useMemo (
		() => {
			return rows.length ? [{
				colspan: isGroup || tableType === 'byPoints' || tableType === 'byDates' ? 2 : 3,
				orders: orders && typeof orders === 'object' && {
					sum: [ 'point', 'phone', '1c' ].reduce (
						( res, cur ) => res + ( ( orders[ cur ] || {} ).sum || 0 ),
						0
					)
				},
				ordersPointCount,
				ordersPoint,
				ordersPhone,
				orders1c,
				workingSeconds,
				visitSeconds,
				pdz,
				sum,
				tasks,
				photo,
				visits,
				workingTime,
				clients,
				dn,
				ordersCount,
				clientCount,
				isTotals: true
			}].concat ( rows ) : [];
		},
		[ isGroup, tableType, orders, visitSeconds, workingSeconds, ordersPointCount, ordersPoint, ordersPhone, orders1c, pdz, sum, tasks, photo, visits, workingTime, ordersCount, rows, clients, clientCount, dn ]
	);

	if ( closed ) return null;

	return (
		<div className={ clsx ( classes.root, className ) }>
			<SideButton className={ classes.closeButton } onClick={ closeTable } />

			<Header count={ rows.length ? rows.length - 1 : 0 } {...{ isGroup, tableType, id, name, description, workingTime, visits, orders, groupId, dn, clientCount, clients, isRange }} />
			{ rows.length ?
				<TableTable {...{ isRange, isGroup, tableType, rows, managerId, groupId, pointsWaiter }} />
				:
				( waiter ? null :
					<div className={ classes.noData }>{ request ? 'Нет данных' : 'Выберите менеджера(ов) и тип отчета в правой панели экрана' }</div>
				)
			}
			<Waiter className={ classes.waiter } show={ waiter || tablePointControllerWaiter } />
		</div>
	);
}


const useStyles = makeStyles ({
	root: {
		position: 'absolute',
		top: 0,
		left: 0,
		bottom: 0,
		right: 0,
		zIndex: 1,
		backgroundColor: '#FFF',
		display: 'flex',
		flexDirection: 'column',
		padding: 26,
		marginRight: ({ panel }) => panel ? 320 : 0
	},
	closeButton: {
		position: 'absolute',
		top: 0,
		right: 0,
		zIndex: 10
	},
	noData: {
		flex: 1,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		fontSize: 20,
		opacity: 0.5
	}
});
