import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { PurePhoto } from 'containers';
import moment from 'moment';
const useStyles = makeStyles ({
	block: {
		width: 52,
		height: 70,
		margin: [[ 6, 10 ]]
	},
	label: {
		fontSize: 12,
		color: '#959595',
		textAlign: 'center',
		marginTop: 4
	}
});


export default React.memo ( PhotoTile );

function PhotoTile ({ date, src })
{
	const classes = useStyles();

	return (
		<div className={ classes.block }>
			<PurePhoto noWaiter {...{ src }} />
			<p className={ classes.label }>
				{ moment ( date, "YYYY-MM-DD" ).format ( 'DD.MM.YY' ) }
			</p>
		</div>
	);
}
