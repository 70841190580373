import React, { useState, useMemo, useCallback, useEffect } from 'react';
import { DateBar } from 'mlk-ui-kit';
import { DatePicker } from 'components';
import moment from 'moment';

const todayPlusWeek = moment().endOf ( 'day' ).add(30, 'days').toDate(),
	getDate = date => moment ( date ).format ( 'YYYY-MM-DD' ),
	incDecDay = ( date, isDec ) => moment ( date )[ isDec ? 'subtract' : 'add' ] ( 1, 'day' ).endOf ( 'day' ).toDate(),
	incDecDate = ( date, isRange, isDec ) => {
		const newDate = incDecDay ( isRange ? date.from : date, isDec );

		return isRange ? { from: newDate, to: newDate } : newDate;
	};


export default React.memo ( Calendar );

function Calendar ({ pointsDate, minDate, table, pointsSetDate, tableSetDate })
{
	const setDate = useCallback ( table ? tableSetDate : pointsSetDate, [ !!table ] ),
		isRange = !!( table && typeof table.date === 'object' && !( table.date instanceof Date ) ),
		date = table ? table.date : pointsDate,
		[ open, setOpen ] = useState ( false ),
		[ preventClose, setPreventClose ] = useState ( false ),
		showRange = isRange && !isPeriodContainOneDay ( table.date ),
		increment = useCallback (
			() => setDate ( incDecDate ( date, isRange, false ) ),
			[ date, isRange, setDate ]
		),
		decrement = useCallback (
			() => setDate ( incDecDate ( date, isRange, true ) ),
			[ date, isRange, setDate ]
		),
		last = useMemo (
			() => showRange ? false : getDate ( isRange ? date.from : date ) === getDate ( todayPlusWeek ),
			[ date, showRange, isRange ]
		),
		handleClick = useCallback (
			() => setOpen ( true ),
			[ setOpen ]
		),
		onClose = useCallback (
			() => setOpen ( false ),
			[ setOpen ]
		);

	useEffect (
		() => {
			setPreventClose ( isRange && !( date.from && date.to ) );
		},
		[ isRange, date ]
	);

	return (
		<React.Fragment>
			<DateBar
				isRange={ showRange }
				nextDisabled={ last }
				onPrev={ decrement }
				onClick={ handleClick }
				onNext={ increment }
			>
				{ showRange &&
					<React.Fragment>
						{ moment ( date.from ).format ( 'D MMMM' ) } -&nbsp;
					</React.Fragment>
				}
				{ moment ( isRange ? date.to : date ).format ( 'D MMMM' ) }
			</DateBar>

			<DatePicker
				date={ isRange ? ( table.date || { from: date, to: date } ) : date }
				maxDate={ todayPlusWeek }
				{...{ isRange, open, minDate, preventClose, setDate, onClose }}
			/>
		</React.Fragment>
	);
}


function isPeriodContainOneDay ( date )
{
	return !!(
		date.from === date.to || date.from.getTime() === date.to.getTime()
	);
}
