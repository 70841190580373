import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { Waiter } from 'components';
import { compareStr } from 'functions';
import PhotoRow from './PhotoRow';
import PhotoTile from './PhotoTile';
import moment from 'moment';
import { usePhotoLoader } from 'hooks';
const useStyles = makeStyles ({
	root: {
		position: 'relative',
		flex: 1,
		overflowY: 'scroll'
	},
	header: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		padding: [[ 8, 26 ]]
	},
	results: {
		fontSize: 12
	},
	content: {
		position: 'relative'
	}
});


export default React.memo ( PhotosFiltered );

function PhotosFiltered ({ photo = {}, dateFrom, dateTo, brands, reset })
{
	const classes = useStyles(),
		[ scheme, setScheme ] = useState ({ ids: [], brands: [] }),
		[ waiter, data ] = usePhotoLoader ( scheme.ids );

	useEffect (
		() => setScheme (
			Object.keys ( photo ).reduce (
				( res, date ) => {
					if ( dateFrom && moment ( date, 'YYYY-MM-DD' ).isBefore ( moment ( dateFrom ).startOf ( 'day' ) ) ) return res;
					if ( dateTo && moment ( date, 'YYYY-MM-DD' ).isAfter ( moment ( dateTo ).endOf ( 'day' ) ) ) return res;

					return photo[ date ].reduce (
						( res, { brand, urls: [ photoId ] = {} } ) => {
							if ( brands && brands.length && !brands.find ( ({ value }) => value === brand ) ) return res;

							if ( !res.photosByBrand[ brand ] ) res.photosByBrand[ brand ] = [];

							res.photosByBrand[ brand ].push ({ date, photoId });
							res.ids.push ( photoId );

							return res;
						},
						res
					);
				},
				{
					photosByBrand: {},
					ids: [],
					getScheme: function ()
					{
						const brands = Object.keys ( this.photosByBrand ).sort();

						brands.forEach (
							brand => this.photosByBrand[ brand ].sort (
								( a, b ) => compareStr ( a.date, b.date )
							)
						);

						return {
							brands,
							photosByBrand: this.photosByBrand,
							ids: this.ids
						}
					}
				}
			).getScheme()
		),
		[ photo, dateFrom, dateTo, brands ]
	);

	return (
		<React.Fragment>
			<div className={ classes.header }>
				<Typography className={ classes.results }>Результаты:</Typography>
				<Button color="primary" onClick={ reset }>Сбросить</Button>
			</div>
			<div className={ classes.content }>
				{ scheme.brands.map ( brand => (
					<PhotoRow key={ brand } title={ brand }>
						{ scheme.photosByBrand[ brand ].map ( ({ photoId, date }) =>
							<PhotoTile
								key={ photoId }
								src={ ( data[ photoId ] || {} ).url }
								{...{ date }}
							/>
						) }
					</PhotoRow>
				) ) }
				<Waiter show={ waiter } />
			</div>
		</React.Fragment>
	);
}
