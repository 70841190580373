import './waiter.css';
import React from 'react';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/styles';
const useStyles = makeStyles ({
	root: {
		margin: 40,
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center'
	},
	error: {
		marginBottom: 20,
		textAlign: 'center'
	}
});


export default function Refresh ({ error, onClick })
{
	const classes = useStyles();

	return (
		<div className={ classes.root }>
			<Typography className={ classes.error }>{ error }</Typography>
			<Button variant="contained" {...{ onClick }}>Повторить</Button>
		</div>
	);
}
