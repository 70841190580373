import { createMuiTheme } from '@material-ui/core/styles';
import { themeOptions } from 'mlk-ui-kit';


export default createMuiTheme ( {
	palette: {
		common: {
			green: '#129C8B',
			blue: '#0066FF',
			yellow: '#FFB054',
			red: '#E51A1A',
			white: '#FFF'
		},
		primary: {
			main: '#22B0E0'
		},
		text: {
			primary: '#000'
		}
	},
	typography: {
		button: {
			textTransform: 'none',
			fontSize: '1.3em'
		},
		fontSize: 12
	},
	overrides: {
		MuiTableCell: {
			root: {
				fontSize: 14
			},
			head: {
				backgroundColor: '#FFF',
				borderBottomWidth: 2
			},
			stickyHeader: {
				backgroundColor: '#FFF'
			}
		},
		MuiDialog: {
			paper: {
				minWidth: 300
			}
		},
		MuiButton: {
			containedPrimary: {
				color: '#FFF'
			}
		}
	}
}, themeOptions );
