import user from './user';
import managers from './managers';
import points from './points';
import panel from './panel';
import filters from './filters';
import table from './table';


export default {
	user,
	managers,
	points,
	panel,
	filters,
	table
};
