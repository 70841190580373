import { put, takeEvery } from 'redux-saga/effects';
import * as actionTypes from 'actionTypes';
import actions from 'actions';


export default function* root ()
{
	yield takeEvery ( actionTypes.ERROR, handleError );
}

export function* handleError ({ payload: { error, runAlert } })
{
	if ( error === 'Нет доступа' ) yield put ( actions.logout() );

	if ( runAlert ) alert ( error );
}
