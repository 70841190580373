import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import Button from '@material-ui/core/Button';
import { Waiter } from './';
const useStyles = makeStyles ({
	container: {
		position: 'relative',
		width: 50,
		height: 50,
		borderRadius: 5,
		overflow: 'hidden',
		backgroundColor: 'rgba(0,0,0,0.05)'
	},
	button: {
		padding: 0,
		minWidth: 'inherit'
	},
	img: {
		objectFit: 'cover',
		width: 50,
		height: 50
	}
});


export default React.memo ( PurePhoto );

function PurePhoto ({ src, className, noWaiter, openPhotoModal })
{
	const classes = useStyles();

	return (
		<div className={ clsx ( classes.container, className ) }>
			{ src ?
				<Button
					className={ classes.button }
					onClick={ () => openPhotoModal ( src.full ) }
				>
					<img className={ classes.img } src={ src[ '50x50' ] } alt="фото" />
				</Button>
				:
				noWaiter ?
					null
					:
					<Waiter show />
			}
		</div>
	);
}
