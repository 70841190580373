import { useEffect } from 'react';
import { openPanel } from 'functions';
import { useSelector } from "react-redux";

export default function TablePointController ({ clusterer, tablePoint, pointsRef, map, setTablePointControllerWaiter: waiter })
{
	const table = useSelector ( st => st.table );
	useEffect (
		() => {
			if ( !clusterer.current || !tablePoint ) return;

			const point = pointsRef.current[
				`${ tablePoint.pointId }:${ tablePoint.managerId }:${ tablePoint.code }`
			];

			if ( !point ) return;

			openBallon ( table, tablePoint, point, clusterer, map, waiter );
		},
		// eslint-disable-next-line
		[ tablePoint ]
	)

	return null;
}



function openBallon ( table, tablePoint, point, clusterer, map, waiter, deep = 0 )
{
	let { name, address, tasks: {closed, opened}} = findRow(table, tablePoint);
	let { name: manager, managerId: managerID  } = table;
	let { pointId: id, code } = tablePoint;

	openPanel ( new Map(Object.entries({id, name, address, closed, opened, manager, managerID, code })));

	const objectState = clusterer.current.getObjectState ( point );

	if ( !objectState.isClustered && objectState.isShown ) point.balloon.open();
	else if ( objectState.isClustered && deep )
	{
		objectState.cluster.state.set ( 'activeObject', point );
		clusterer.current.balloon.open ( objectState.cluster );
	}
	else
	{
		waiter ( true );

		return map.setZoom ( 17 )
			.then (
				() => map.panTo (
						point.geometry.getCoordinates().map ( parseFloat ),
						{ useMapMargin: true }
					)
					.then ( () => openBallon (table, tablePoint, point, clusterer, map, waiter, deep + 1 ) )
			);
	}

	waiter ( false );
}

function findRow(table, tablePoint) {
	return table.rows.find(obj => {
		return obj.pointId === tablePoint.pointId
	})
}
