import React, { useState, useEffect, useCallback } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { SuggestDropdown, ScrollIntoView, FigmaIcon } from 'components';
import Calendar from './Calendar';
import PhotosBase from './PhotosBase';
import PhotosFiltered from './PhotosFiltered';
import PhotoByTasks from './PhotoByTasks';
import moment from 'moment';
const containerStyles = {
	width: 'calc(100% - 40px)',
	margin: '12px 20px'
};


export default function Photos ({ photo = {}, pointID, managerID, byTasksDate, byTasksRange })
{
	const classes = useStyles(),
		[ data, setData ] = useState ( {} ),
		[ brands, setBrands ] = useState ( [] ),
		[ date, setDate ] = useState ( {} ),
		[ showPhotoByTasks, setShowPhotoByTasks ] = useState ( false ),
		toggleShowPhotoByTasks = useCallback (
			() => setShowPhotoByTasks ( !showPhotoByTasks ),
			[ showPhotoByTasks ]
		),
		{ options, minDate, maxDate } = data,
		{ from: dateFrom, to: dateTo } = date;

	useEffect (
		() => setData (
			getData ( photo )
		),
		[ photo ]
	);

	return (
		<React.Fragment>
			<div className={ classes.root }>
				<SuggestDropdown
					label="Марки"
					value={ brands }
					onChange={ setBrands }
					{...{ options, containerStyles }}
				/>
				<div className={ classes.row }>
					<Calendar
						isRange
						placeholder="Выберите период"
						buttonClassName={ classes.calendarButton }
						{...{ date, minDate, maxDate, setDate }}
					/>
				</div>
			</div>
			<div className={ classes.photos }>
				{ ( ( brands && brands.length ) || dateFrom || dateTo ) ?
					<PhotosFiltered
						reset={ () => {
							setBrands ( [] );
							setDate ( {} );
						} }
						{...{ photo, dateFrom, dateTo, brands }}
					/>
					:
					<PhotosBase {...{ photo }} />
				}
				{ showPhotoByTasks &&
					<ScrollIntoView>
						<Button
							color="primary"
							onClick={ toggleShowPhotoByTasks }
							endIcon={ <FigmaIcon name="chevronDown" className={ classes.icon } /> }
						>
							Фото по задачам
						</Button>
						<PhotoByTasks active={ showPhotoByTasks } {...{ pointID, managerID, date: byTasksDate, range: byTasksRange }} />
					</ScrollIntoView>
				}
			</div>
			{ !showPhotoByTasks &&
				<Button
					color="primary"
					onClick={ toggleShowPhotoByTasks }
					endIcon={ <FigmaIcon name="chevronUp" className={ classes.icon } /> }
				>
					Фото по задачам
				</Button>
			}
		</React.Fragment>
	);
}


const useStyles = makeStyles ({
	root: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'stretch',
		borderBottom: '1px solid rgb(227, 227, 227)'
	},
	row: {
		display: 'flex',
		alignItems: 'center',
		padding: [[ 0, 20 ]]
	},
	calendarButton: {
		flex: 1,
		marginLeft: 0,
		marginRight: 0
	},
	delimiter: {
		color: '#959595',
		fontSize: 12,
		margin: [[ -10, 8, 0, 8 ]]
	},
	photos: {
		display: 'flex',
		flexDirection: 'column',
		flex: 1,
		overflowY: 'scroll',
		position: 'relative'
	},
	icon: {
		fontSize: '16px !important',
		marginLeft: -4
	}
});

function getData ( photo )
{
	const data = Object.keys ( photo ).reduce (
		( result, dateStr ) => photo[ dateStr ].reduce (
			( res, { brand, urls: [ photoId ] = [] } ) => {
				if ( !res.brands[ brand ] )
				{
					res.options.push ({ label: brand, value: brand, photoId });
					res.brands[ brand ] = true;
				}

				const date = moment ( dateStr, 'YYYY-MM-DD' );

				if ( !res.minDate || date.isBefore ( res.minDate ) ) res.minDate = date;
				if ( !res.maxDate || date.isAfter ( res.maxDate ) ) res.maxDate = date;

				return res;
			},
			result
		),
		{ options: [], minDate: '', maxDate: '', brands: {} }
	);

	delete data.brands;

	if ( data.minDate ) data.minDate = data.minDate.toDate();
	if ( data.maxDate ) data.maxDate = data.maxDate.toDate();

	return data;
}
