import React, { useCallback, useMemo } from 'react';
import Select from 'react-select';
import { emphasize, makeStyles, useTheme } from '@material-ui/core/styles';
import NoSsr from '@material-ui/core/NoSsr';
import components from './components';
const noOptionsMessage = () => 'Пусто';


export default React.memo ( SuggestDropdown );

function SuggestDropdown ({ placeholder = '', label, options, value, containerStyles, onChange: handleChange })
{
	const classes = useStyles(),
		theme = useTheme(),
		styles = useMemo ( () => ({
			container: base => ({
				...base,
				display: 'flex',
				...containerStyles
			}),
			input: base => ({
				...base,
				color: theme.palette.text.primary,
				'& input': {
					font: 'inherit',
				}
			}),
			clearIndicator: base => ({
				...base,
				cursor: 'pointer'
			}),
			dropdownIndicator: base => ({
				...base,
				cursor: 'pointer'
			})
		}), [ theme, containerStyles ] );

	const [ focused, setFocused ] = React.useState ( false ),
		onChange = useCallback (
			value => handleChange ( value ),
			[ handleChange ]
		),
		onFocus = useCallback (
			() => setFocused ( true ),
			[ setFocused ]
		),
		onBlur = useCallback (
			() => setFocused ( false ),
			[ setFocused ]
		);

	return (
		<NoSsr>
			<Select
				isMulti
				TextFieldProps={{
					className: classes.outerInput,
					InputLabelProps: {
						shrink: !!( value && value.length ) || focused
					},
					label,
					onFocus,
					onBlur
				}}
				{...{ classes, styles, components, placeholder, options, value, onChange, noOptionsMessage }}
			/>
		</NoSsr>
	);
}


const useStyles = makeStyles ( theme => ({
	outerInput: {
		flex: 1
	},
	input: {
		flex: 1,
		display: 'flex',
		padding: [[ 0, 10 ]],
		height: 'auto'
	},
	inputPadding: {
		padding: 10
	},
	valueContainer: {
		display: 'flex',
		flexWrap: 'wrap',
		flex: 1,
		alignItems: 'center',
		overflow: 'hidden'
	},
	chip: {
		margin: theme.spacing ( 0.5, 0.25 )
	},
	missedOptionChip: {
		color: '#E51A1A'
	},
	chipFocused: {
		backgroundColor: emphasize (
			theme.palette.type === 'light' ? theme.palette.grey[ 300 ] : theme.palette.grey[ 700 ],
			0.1 // 0.08
		)
	},
	noOptionsMessage: {
		padding: theme.spacing ( 1, 2 )
	},
	singleValue: {
		fontSize: 16
	},
	placeholder: {
		position: 'absolute',
		left: 2,
		bottom: 6,
		fontSize: 16
	},
	paper: {
		position: 'absolute',
		zIndex: 2,
		marginTop: 0,
		left: 0,
		right: 0,
		top: '100%'
	},
	divider: {
		height: theme.spacing ( 2 )
	}
}) );
