import { Points } from 'components';
import { connect } from 'react-redux';
import actions from 'actions';


export default connect (
	({ points, panel, managers: { ids, managersMap } }) => ({ ...points, ids, managersMap, panel: !!panel }),
	dispatch => ({
		requestPointsBackground: () => dispatch ( actions.requestPointsBackground() ),
		setBackground: background => dispatch ( actions.setBackground ( background ) ),
		setTablePointControllerWaiter: status => dispatch ( actions.setTablePointControllerWaiter ( status ) )
	})
)( Points );
