import React, { useState, useCallback } from 'react';
import { SelectDialog } from 'components';
import { useDispatch, useSelector } from 'react-redux';
import { SelectTable } from 'context';
import actions from 'actions';
const options = [
	{ label: 'По датам', value: 'byDates' },
	{ label: 'По клиентам', value: 'byPoints' }
];


export default React.memo ( SelectTableProvider );

function SelectTableProvider ({ children })
{
	const [ open, openSelectTableDialog, value, onSelect, onClose ] = useSelectTableDialog();

	return (
		<SelectTable.Provider value={ openSelectTableDialog }>
			{ children }
			<SelectDialog
				title="Выберите вариант отчета"
				confirmButtonTitle="Удалить"
				{...{ open, options, value, onSelect, onClose }}
			/>
		</SelectTable.Provider>
	);
}


function useSelectTableDialog ()
{
	const dispatch = useDispatch(),
		value = useSelector (
			({ table }) => table && table.tableType
		),
		[ payload, setPayload ] = useState ( false ),
		openSelectTableDialog = useCallback (
			payload => setPayload ( payload || {} ),
			[]
		),
		onSelect = useCallback (
			tableType => {
				dispatch (
					actions.tableRequest ({ ...payload, tableType, closed: false })
				);

				dispatch (
					actions.clearLastSelected ()
				);

				setPayload ( false );
			},
			[ payload, dispatch ]
		),
		onClose = useCallback (
			() => {
				setPayload ( false );
				dispatch (
					actions.clearLastSelected ()
				);
			},
			[dispatch]
		);

	return [ !!payload, openSelectTableDialog, value, onSelect, onClose ];
}
