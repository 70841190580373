import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import { withStyles } from '@material-ui/styles';
const styles = {
	content: {
		minWidth: 300
	}
};


export default React.memo ( withStyles ( styles )( NoManagersDialog ) );

function NoManagersDialog ({ classes, open, logout })
{
	return (
		<Dialog
			disableBackdropClick
			disableEscapeKeyDown
			{...{ open }}
		>
			<DialogContent className={ classes.content }>
				<DialogContentText>
					К вам не подключен ни один менеджер
				</DialogContentText>
			</DialogContent>

			<DialogActions>
				<Button color="primary" onClick={ logout }>
					ОК
				</Button>
			</DialogActions>
		</Dialog>
	);
}
