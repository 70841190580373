import React, { useState } from 'react';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { FigmaIcon } from './';
import { withStyles } from '@material-ui/styles';
const styles = {
	content: {
		minWidth: 300
	},
	userButton: {
		width: 37,
		height: 37,
		background: 'linear-gradient(121.02deg, #F8B2FF 0%, #22B0E0 76.52%, #2C5B73 148.1%), #C4C4C4',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		padding: 0
	},
	userIcon: {
		fontSize: 30,
		padding: 0
	},
	phone: {
		textDecoration: 'none',
		color: 'inherit'
	}
};


export default withStyles ( styles )( LogoutDialog );

function LogoutDialog ({ classes, name, phone = '', logout })
{
	const [ open, setOpen ] = useState ( false ),
		close = () => setOpen ( false );

	return (
		<React.Fragment>
			<IconButton className={ classes.userButton } onClick={ () => setOpen ( true ) }>
				<FigmaIcon className={ classes.userIcon } name="user" htmlColor="#FFF" />
			</IconButton>

			<Dialog onClose={ close } {...{ open }}>
				<DialogTitle>{ name }</DialogTitle>

				<DialogContent className={ classes.content }>
					<DialogContentText>
						{ phone &&
							<a
								className={ classes.phone }
								href={ 'tel:+7' + phone }
							>
								+7 { phone.replace ( /(\d{3})(\d{3})(\d{2})(\d{2})/, '($1) $2-$3-$4' ) }
							</a>
						}
					</DialogContentText>
				</DialogContent>

				<DialogActions>
					<Button onClick={ close } color="primary">
						Отмена
					</Button>
					<Button onClick={ logout } color="primary" autoFocus>
						Выйти
					</Button>
				</DialogActions>
			</Dialog>
		</React.Fragment>
	);
}
