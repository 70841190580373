export default function getSortFunction ( orderBy, asc, isText )
{
	return asc ? ( a, b ) => - desc ( a, b, orderBy, isText ) : ( a, b ) => desc ( a, b, orderBy, isText );

	function desc ( a, b, orderBy, isText )
	{
		a = getValue ( orderBy, a );
		b = getValue ( orderBy, b );

		if ( !isText )
		{
			a = parseFloat ( a ) || 0;
			b = parseFloat ( b ) || 0;
		}

		if ( b < a ) return -1;
		if ( b > a ) return 1;

		return 0;
	}

	function getValue ( orderBy, obj )
	{
		if ( Array.isArray ( orderBy ) ) return getValueByPath ( orderBy, obj );

		return obj[ orderBy ];
	}

	function getValueByPath ( path, obj )
	{
		return path.reduce ( ( res, prop ) => ( res || {} )[ prop ], obj );
	}
}
