import { actions as user } from 'reducers/user';
import { actions as managers } from 'reducers/managers';
import { actions as points } from 'reducers/points';
import { actions as panel } from 'reducers/panel';
import { actions as filters } from 'reducers/filters';
import { actions as table } from 'reducers/table';


export default {
	...user,
	...managers,
	...points,
	...panel,
	...filters,
	...table
};
