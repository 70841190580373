import { createActions, handleActions, combineActions } from 'redux-actions';
import * as actionTypes from 'actionTypes';

const defaultState = {
	options: {},
	values: {
		visited: null
	},
	indicator: false,
	waiter: true
};


export const actions = createActions ({
	[ actionTypes.INIT_FILTERS_REQUEST ]: () => + new Date(),
	[ actionTypes.INIT_FILTERS ]: ( options, waiter ) => ({ options, waiter }),
	[ actionTypes.SET_FILTERS ]: values => values,
	[ actionTypes.SET_FILTERS_INDICATOR ]: indicator => indicator,
	[ actionTypes.RESET_FILTERS ]: null,
	[ actionTypes.UPDATED_FILTERS ]: () => {},
});

export default handleActions (
	{
		[ actionTypes.LOGOUT ]: () => defaultState,
		[ actionTypes.INIT_FILTERS_REQUEST ]: ( state, { payload: waiter } ) => ({ ...state, waiter }),
		[ actionTypes.INIT_FILTERS ]: ( state, { payload: { options, waiter } } ) => state.waiter === waiter ? ({ ...state, options, waiter: false }) : state,
		[ actionTypes.SET_FILTERS ]: ( state, { payload: values } ) => ({ ...state, values: { ...state.values, ...values } }),
		[ actionTypes.SET_FILTERS_INDICATOR ]: ( state, { payload: indicator } ) => ({ ...state, indicator }),
		[ actionTypes.RESET_FILTERS ]: (state) => ({ ...state, values: defaultState.values, indicator: defaultState.indicator }),
		[ combineActions ( actionTypes.SET_FILTERS, actionTypes.RESET_FILTERS, actionTypes.SELECT_MANAGERS ) ]: (state) => ({ ...state, changed: true }),
		[ actionTypes.POINTS_REQUEST]: (state) => ({ ...state, changed: false }),
		[ actionTypes.UPDATED_FILTERS ]: (state) => ({ ...state }),
	},
	defaultState
);
