import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
const orderColors = {
		point: 'green',
		phone: 'blue',
		'1c': 'yellow'
	},
	useStyles = makeStyles ( theme => ({
		orderCount: {
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
			minWidth: 18,
			height: 18,
			borderRadius: 5,
			color: '#FFF',
			backgroundColor: ({ orderType }) => theme.palette.common[ orderColors[ orderType ] ],
			padding: [[ 0, 4 ]],
			fontSize: 12
		}
	}) );


export default React.memo ( OrdersCountBadge );

function OrdersCountBadge ({ className, orderType, count })
{
	const classes = useStyles ({ orderType });

	return (
		<div className={ clsx ( classes.orderCount, className ) }>
			{ count }
		</div>
	);
}
