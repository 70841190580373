import React, { useMemo, useEffect, useState } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import { FigmaIcon } from 'components';
import getSortFunction from './getSortFunction';
import Row from './Row';
const managerNames = {
		default: [
			'n',
			'name',
			'visitTime',
			'visitSeconds',
			'pdz',
			'orders',
			'tasks',
			'photo',
			'pointCode'
		],
		byDates: [
			'date',
			'status',
			'visits',
			'workingSeconds',
			'ordersPointCount',
			'ordersPoint',
			'ordersPhone',
			'orders1c',
			'sum',
			'tasks',
			'photo',
		],
		byPoints: [
			'n',
			'name',
			'visits',
			'visitSeconds',
			'ordersPointCount',
			'ordersPoint',
			'ordersPhone',
			'orders1c',
			'sum',
			'tasks',
			'photo',
			'pointCode',
		]
	},
	groupNames = {
		default: [
				'n',
				'name',
				'dn',
				'visits',
				'workingTime',
				'ordersCount',
				'sum',
				'pdz',
				'tasks',
				'photo',
		],
		summary: [
			'n',
			'name',
			'dn',
			'clients',
			'visits',
			'workingDayCount',
			'workingTimeAvg',
			'ordersPointCount',
			'ordersPoint',
			'ordersPhone',
			'orders1c',
			'tasks',
			'photo',
			'clientCount'
		]
	},
	managerTitles = {
		n: '#',
		name: 'Наименование',
		dn: 'DN',
		clients: 'Клиенты',
		visits: 'Визиты',
		visitTime: 'Начало визита',
		visitSeconds: 'Время визита',
		workingSeconds: 'Раб. время',
		pdz: 'ПДЗ',
		sum: 'Сумма',
		orders: 'Сумма',
		tasks: 'Задачи',
		photo: '',
		pointCode: '',
		ordersPointCount: 'ТП, кол-во',
		ordersPoint: 'ТП',
		ordersPhone: 'ТП тел',
		orders1c: '1С',
		status: 'Статус',
		date: 'Дата'
	},
	managerTitlesOverrides = {
		byPoints: {
			sum: 'Итого'
		},
		byDates: {
			workingSeconds: 'Раб. время',
		}
	},
	groupTitles = {
		...managerTitles,
		name: 'Менеджер',
		workingDayCount: 'Кол-во дней',
		workingTime: 'Раб. время',
		workingTimeAvg: 'Ср. время',
		ordersCount: 'Заказы',
		sum: 'Сумма',
		managerID: ''
	},
	groupTitlesOverrides = {
		summary: {
			workingTimeAvg: 'Ср. время',
		},
	},
	colWidth = {
		n: 50,
		date: 113,
		name: 280,
		dn: 80,
		clients: 140,
		visits: 140,
		visitTime: 85,
		visitSeconds: 90,
		workingSeconds: 90,
		workingTime: 110,
		workingTimeAvg: 110,
		workingDayCount: 50,
		ordersPointCount: 60,
		ordersCount: 80,
		ordersPoint: 105,
		ordersPhone: 105,
		orders1c: 105,
		sum: 110,
		pdz: 100,
		orders: 100,
		tasks: 100,
		photo: 70,
		pointCode: 55
	},
	groupColWidthOverrides = {
		visits: 140
	},
	useStyles = makeStyles ( theme => ({
		root: {
			overflow: 'auto',
			flex: 1
		},
		table: {
			tableLayout: 'fixed',
			minWidth: 972,
		},
		head: {
			padding: 12,
			paddingRight: 0,
			overflow: 'hidden',
			left: 'unset'
		},
		title: {
			overflow: 'hidden',
			textOverflow: 'ellipsis',
			flexShrink: 1,
			alignItems: 'center'
		},
		icon: {
			display: 'block'
		},
		nowrap: {
			whiteSpace: 'nowrap'
		},
		sortLabel: {
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
			overflow: 'hidden',
			'&:hover': {
				color: theme.palette.primary.main
			},
			'& .MuiTableSortLabel-icon': {
				margin: [[ 0, 3 ]]
			}
		},
		activeSortLabel: {
			color: [ theme.palette.primary.main, '!important' ]
		},
		left: {
			justifyContent: 'flex-start'
		}
	}) ),

	isText = {
		name: true,
		visitTime: true
	},
	orderByOverrides = {
		tasks: [ 'tasks', 'closed' ],
		visits: [ 'visits', 'visited', 'nearby' ],
		orders: [ 'orders', 'sum' ]
	},

	iconStyle = { color: '#22B0E0', fontSize: 10 },
	IconComponent = props => <FigmaIcon name="chevronDown" { ...props } style={ iconStyle } />;


export default React.memo ( TableTable );

function TableTable ({ isRange, isGroup, tableType = 'default', rows = [], managerId, groupId, pointsWaiter })
{
	const classes = useStyles(),
		cellWidth = useMemo (
			() => calcPercent (
				isGroup ? { ...colWidth, ...groupColWidthOverrides } : colWidth,
				1024
			),
			[ isGroup ]
		),
		names = isGroup ? ( groupNames[ tableType ] || groupNames.default ) : ( managerNames[ tableType ] || managerNames.default ),
		titles = useMemo (
			() => isGroup ? { ...groupTitles, ...groupTitlesOverrides[ tableType ] } : { ...managerTitles, ...managerTitlesOverrides[ tableType ] },
			[ isGroup, tableType ]
		),
		[ asc, setAsc ] = useState ( true ),
		[ orderBy, setOrderBy ] = useState ( tableType === 'byDates' ? 'date' : 'n' );



	useEffect (
		() => {
			setAsc ( true );
			setOrderBy ( tableType === 'byDates' ? 'date' : 'n' );
		},
		[ managerId, groupId, tableType ]
	);

	return (
		<div className={ classes.root }>
			<Table stickyHeader className={ classes.table }>
				<TableHead>
					<TableRow>
						{ names.map ( name =>
							<TableCell
								key={ name }
								style={{ width: cellWidth[ name ] }}
								className={ clsx ( classes.head, { [ classes.nowrap ]: name !== 'name' } ) }
							>
								{ name === 'managerID' || name === 'pointCode' ?
									titles[ name ]
									:
									<TableSortLabel
										className={ clsx ( classes.sortLabel, {
											[ classes.activeSortLabel ]: orderBy === name,
											[ classes.left ]: name === 'name' || name === 'date'
										}) }
										active={ orderBy === name }
										direction={ asc ? 'asc' : 'desc' }
										onClick={ () => {
											if ( name === orderBy ) setAsc ( !asc );
											else setOrderBy ( name );
										} }
										{...{ IconComponent }}
									>
										<div className={ classes.title } title={ titles[ name ] }>
											{ name === 'photo' ?
												<FigmaIcon className={ classes.icon } name="camera" />
												:
												titles[ name ]
											}
										</div>
									</TableSortLabel>
								}
							</TableCell>
						) }
					</TableRow>
				</TableHead>
				<TableBody>
					{ rows[ 0 ] &&
						<Row
							isHead
							row={ rows[ 0 ] }
							{...{ isGroup, names, managerId, groupId, pointsWaiter }}
						/>
					}
					{ rows.slice ( 1 ).map ( ( row, n ) => ({ ...row, n }) ).sort ( getSortFunction ( orderByOverrides[ orderBy ] || orderBy, asc, isText[ orderBy ] ) ).map ( row => (
						<Row
							key={ ( isGroup ? row.managerID : row.pointCode ) || row.n }
							{...{ isRange, isGroup, row, names, managerId, groupId, pointsWaiter }}
						/>
					) ) }
				</TableBody>
			</Table>
		</div>
	);
}


function calcPercent ( obj, minWidth )
{
	return Object.keys ( obj ).reduce (
		( res, key ) => ({ ...res, [ key ]: `${ Math.ceil ( obj[ key ] / minWidth * 100 ) }%` }),
		{}
	);
}
