import React, { useCallback } from 'react';
import { List, ListItem, ListItemText, Divider, makeStyles } from '@material-ui/core';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import actions from 'actions';


export default React.memo ( HeaderProfileBody );

function HeaderProfileBody ({ className, iconClassName, chevron, onClick })
{
	const classes = useStyles(),
		dispatch = useDispatch(),
		{ phone, full_name } = useSelector ( st => st.user, shallowEqual ),
		logout = useCallback (
			() => dispatch ( actions.logout() ),
			[ dispatch ]
		);

	return (
		<List disablePadding className={ classes.list }>
			<ListItem>
				<ListItemText
					className={ classes.center }
					primary={
						<React.Fragment>
							{ full_name }
							<br />
							{ !!phone &&
								<React.Fragment>
									+7 { phone.replace ( /(\d{3})(\d{3})(\d{2})(\d{2})/, '($1) $2-$3-$4' ) }
								</React.Fragment>
							}
						</React.Fragment>
					}
				/>
			</ListItem>
			<Divider />
			<ListItem button onClick={ logout }>
				<ListItemText className={ classes.logout } primary="Выйти" />
			</ListItem>
		</List>
	);
}


const useStyles = makeStyles ( theme => ({
	list: {
		minWidth: 150
	},
	center: {
		textAlign: 'center'
	},
	logout: {
		textAlign: 'center',
		color: theme.palette.primary.main
	}
}) );
