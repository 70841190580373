import { useEffect } from 'react';
import { useSelector } from "react-redux";

export default function BoundsController ({
	map,
	points,
	ids,
	managersMap,
	preventAutoZoom,
	background,
	filters: { visited, notVisited } })
{
	const panel = useSelector ( st => st.panel );
	useEffect (
		() => {
			if ( map && !preventAutoZoom && !background && !panel)
			{
				// Иначе точки не успевают попасть в geoObjects
				setTimeout (
					() => {
						const bounds = map.geoObjects.getBounds();

						if ( bounds ) map.setBounds ( bounds, { checkZoomRange: true, useMapMargin: true } );
					},
					0
				);
			}
		},
		// eslint-disable-next-line
		[ map, points, ids, managersMap, visited, notVisited ]
	)

	return null;
}
