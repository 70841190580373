import React from 'react';
import { version } from '../../package.json';
import Typography from '@material-ui/core/Typography';
import { AuthComponent, AuthLogo, AuthVersion, Waiter } from 'mlk-ui-kit';
import { makeStyles } from '@material-ui/styles';

const dev = process.env.NODE_ENV === 'development',
	initialValues = { phone: dev ? '0857654414' : '', code: dev ? '2369' : '' };


export default function Auth ({ waiter, confirm = false, error, authRequest, confirmRequest, logout })
{
	const classes = useStyles();

	return (
		<AuthComponent
			onBack={ logout }
			onSubmit={ values => confirm ? confirmRequest ( values.code ) : authRequest ( values.phone ) }
			{...{ confirm, initialValues }}
		>
			<AuthLogo src="./logo.svg" alt="logo" />
			{ !!error &&
				<Typography className={ classes.error }>{ error }</Typography>
			}
			<AuthVersion>{ version }</AuthVersion>
			{ waiter &&
				<Waiter />
			}
		</AuthComponent>
	);
}


const useStyles = makeStyles ({
	error: {
		position: 'absolute',
		marginTop: 100
	}
});
