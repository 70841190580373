import { openPanel } from 'functions';


export default function getTemplates ( ymaps )
{
	return {
		clusterer: ymaps.templateLayoutFactory.createClass (
				`<div class="cluster"><span>{{ properties.geoObjects.length }}</span></div>
				<div class="clusterPDZ"><span>{{ properties.pdz }}</span></div>`
			),
		position: ymaps.templateLayoutFactory.createClass (
				`<div class="placemark">
					<svg style="position:absolute" width="37" height="17" viewBox="0 0 37 17" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path d="M2.97974 16.5V1.93182V1L29.7974 1.90735e-06V17L2.97974 16.5Z" fill="white"/>
						<path d="M37 12.8367L37 4.16259C37 1.86332 34.4598 1.78898e-06 32.1761 1.68257e-06L4.13521 4.87759e-07C1.8522 3.81377e-07 -2.74237e-07 1.86332 -3.68517e-07 4.16259L-7.2419e-07 12.8367C-8.1844e-07 15.1352 1.85146 17 4.13521 17L32.1761 17C34.4598 16.9985 37 15.1352 37 12.8367ZM21.0642 14.4087C21.9192 14.9394 21.5626 16.2705 20.5563 16.2705L15.5173 16.2705L15.2601 14.2574L20.5353 14.2574C20.7216 14.2574 20.9058 14.3104 21.0642 14.4087V14.4087ZM26.8338 14.3047C26.6962 14.8177 26.1593 15.1184 25.6616 14.9331C24.6074 14.5405 24.3313 14.1449 24.2844 13.8832C24.248 13.68 24.3345 13.4665 24.3345 13.2601L24.3345 3.92749C24.3345 3.60922 24.486 3.30996 24.7425 3.12155L25.4637 2.59182C26.0092 2.19114 26.7688 2.43977 26.9205 3.09937C27.4077 5.21749 28.0898 9.62017 26.8338 14.3047ZM20.8335 2.61332C20.6752 2.71161 20.4908 2.76473 20.3044 2.76473L16.3944 2.76473C15.7921 2.76473 15.3275 2.22848 15.4033 1.631V1.631C15.4668 1.13143 15.8926 0.750933 16.3961 0.750933L20.3267 0.750934C21.333 0.750934 21.6885 2.08261 20.8335 2.61332V2.61332ZM7.69776 2.31374C7.25759 1.64898 7.72717 0.750933 8.52445 0.750933L13.1201 0.750933C13.7216 0.750933 14.1859 1.28573 14.1115 1.88252V1.88252C14.049 2.38304 13.6228 2.76473 13.1184 2.76473L8.53359 2.76473C8.19813 2.76473 7.88297 2.59344 7.69776 2.31374V2.31374ZM3.01867 3.77222C3.01867 2.97267 3.9105 2.49645 4.57493 2.94121L5.01913 3.23855C5.29642 3.42417 5.46286 3.73587 5.46286 4.06955L5.46286 13.16C5.46286 13.4936 5.29649 13.8052 5.0193 13.9909L4.5751 14.2883C3.91069 14.7333 3.01866 14.2571 3.01866 13.4575L3.01867 3.77222ZM8.22572 14.2574L13.9933 14.2574L14.2534 16.2705L8.75399 16.2705C7.95652 16.2705 7.47988 15.3827 7.92044 14.718L8.22572 14.2574Z" fill="$[properties.color]"/>
						<path fill-rule="evenodd" clip-rule="evenodd" d="M34.7636 7.5H29.7974V6.5H34.7636V7.5ZM29.7974 9.5H34.7636V10.5H29.7974V9.5Z" fill="white"/>
					</svg>
					<div class="position initials"><span>$[properties.initials]</span></div>
				<div>`
			),
		point: getPointTemplate(),
		base: getBasePointTemplate(),
		balloon: getBalloonTemplate(),
		clusterBaloon: getBalloonTemplate ( true ),
		balloonContent: getBalloonContentTemplate()
	};

	function getPointTemplate ( isBase )
	{
		return ymaps.templateLayoutFactory.createClass (
			`<div class="placemark">
				${ isBase ? '' :
					`<div class="placemark-block" style="border-color:$[properties.color]">
						<p class="pdz">$[properties.pdz]<span class="rub"> ₽</span></p>
						<p class="sum">$[properties.sum]<span class="rub"> ₽</span></p>
						<p class="tasks">
							<span class="closed">$[properties.closed]</span>/<span class="{% if ( properties.opened === properties.closed) %}closed{% else %}opened{% endif %}">$[properties.opened]</span>
							{% if properties.photo %}<img class="camera" src="camera.svg" />{% endif %}
						<p>
					</div>`
				}
				<svg style="position:absolute" width="28" height="32" viewBox="0 0 28 32" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M17.5637 27.2049C23.2487 25.5147 27.4 20.1897 27.4 13.8823C27.4 6.21532 21.2663 0 13.7 0C6.1337 0 0 6.21532 0 13.8823C0 20.1897 4.15126 25.5147 9.83628 27.2049L13.7 31.12L17.5637 27.2049Z" fill="$[properties.color]"/></svg>
				<div class="initials"><span>$[properties.initials]</span></div>
				${ isBase ? '' :
					`<div class="status"><div class="$[properties.visited] $[properties.nearby]"></div></div>`
				}
			<div>`
		);
	}

	function getBasePointTemplate ()
	{
		return ymaps.templateLayoutFactory.createClass (
			`<div class="placemark">
				<svg style="position:absolute" width="28" height="32" viewBox="0 0 28 32" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path d="M17.805 27.4951L17.6816 27.5321L17.5914 27.6241L14 31.2859L10.4086 27.6241L10.3184 27.5321L10.195 27.4951C4.59672 25.8204 0.5 20.5393 0.5 14.2749C0.5 6.65801 6.55328 0.5 14 0.5C21.4467 0.5 27.5 6.65801 27.5 14.2749C27.5 20.5393 23.4033 25.8204 17.805 27.4951Z" fill="white" stroke="black"/>
				</svg>
				<div class="initials base"><span>$[properties.initials]</span></div>
			<div>`
		);
	}

	function getBalloonTemplate ( isCluster )
	{
		const width = isCluster ? 480 : 235,
			BalloonLayout = ymaps.templateLayoutFactory.createClass (
			`<div class="balloon">
				$[[options.contentLayout observeSize minWidth=${ width } maxWidth=${ width } maxHeight=350 minHeight=50]]
				<div class="arrow"></div>
			</div>`,
			{
				build: function ()
				{
					this.constructor.superclass.build.call ( this );

					this._element = this.getParentElement().querySelector ( 'div.balloon' );
					this._arrow = this._element.querySelector ( 'div.arrow' );

					if ( isCluster ) this._arrow.style.left = '68.5px';

					this.applyElementOffset();
				},
				onSublayoutSizeChange: function ()
				{
					BalloonLayout.superclass.onSublayoutSizeChange.apply ( this, arguments );

					if ( !this._isReady() ) return;

					this.applyElementOffset();

					this.events.fire ( 'shapechange' );
				},
				applyElementOffset: function ()
				{
					const left = ( isCluster ? -68.5 : - this._element.offsetWidth / 2 ) + 'px',
						top = - ( this._element.offsetHeight + this._arrow.offsetHeight ) + 'px';

					this._element.style.left = left;
					this._element.style.top = top;
				},
				_isReady: function ()
				{
					return this._element && this._arrow;
				},
				getShape: function ()
				{
					if ( !this._isReady() )
					{
						return BalloonLayout.superclass.getShape.call ( this );
					}

					const { offsetLeft, offsetTop, offsetWidth, offsetHeight } = this._element;

					return new ymaps.shape.Rectangle (
						new ymaps.geometry.pixel.Rectangle ([
							[ offsetLeft, offsetTop ],
							[ offsetLeft + offsetWidth, offsetTop + offsetHeight + this._arrow.offsetHeight ]
						])
					);
				}
			}
		);

		return BalloonLayout;
	}

	function getBalloonContentTemplate ()
	{
		const BalloonContentLayout = ymaps.templateLayoutFactory.createClass (
			`<div>
				{{ properties.balloonContent|raw }}
				<div class="button">
					<div class="circle"></div>
					<div class="circle"></div>
					<div class="circle"></div>
				</div>
			</div>`,
			{
				build: function ()
				{
					this.constructor.superclass.build.call ( this );

					this._button = this._element.querySelector ( 'div.button' );
					this._callback = () => openPanel ( this.getData().properties );

					this._button.addEventListener ( 'click', this._callback );

					openPanel ( this.getData().properties, true );
				},
				clear: function ()
				{
					this._button.removeEventListener ( 'click', this._callback );

					BalloonContentLayout.superclass.clear.call ( this );
				}
			}
		);

		return BalloonContentLayout;
	}
}
