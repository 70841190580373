import { put } from 'redux-saga/effects';
import actions from 'actions';
import api from 'api';


export default function* init ()
{
	const token = api.storage.get ( 'token' );

	if ( token ) yield put ( actions.login ( token ) );
}
