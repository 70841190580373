import React, { useState } from 'react';
import clsx from 'clsx';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import { TaskRow, FigmaIcon } from 'components';
const Accordion = withStyles ({
		root: {
			backgroundColor: 'transparent',
			borderLeft: 'none',
			borderRight: 'none',
			boxShadow: 'none',
			padding: 0,
			'&:not(:last-child)': {
				borderBottom: 0
			},
			'&:before': {
				display: 'none'
			},
			'&$expanded': {
				margin: 0
			}
		},
		expanded: {}
	})( MuiAccordion ),

	AccordionSummary = withStyles ({
		root: {
			border: 0,
			padding: 0,
			minHeight: 0,
			'&$expanded': {
				minHeight: 0
			}
		},
		content: {
			display: 'block',
			'&$expanded': {
				margin: [[ 12, 0 ], '!important' ]
			}
		},
		expanded: {
			minHeight: 0
		}
	})( MuiAccordionSummary ),

	AccordionDetails = withStyles ( theme => ({
		root: {
			padding: [[ 0, 0, 12, 0 ]],
			display: 'block'
		}
	}))( MuiAccordionDetails ),

	useStyles = makeStyles ( theme => ({
		root: {
			display: 'flex',
			flexDirection: 'column',
			flex: 1,
			overflowY: 'scroll'
		},
		header: {
			display: 'flex',
			alignItems: 'center'
		},
		section: {
			width: 40,
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center'
		},
		completed: {
			marginBottom: -3,
			marginRight: 8
		},
		button: {
			width: 28,
			height: 28,
			padding: 0
		},
		icon: {
			fontSize: 12,
			transform: 'rotateZ(180deg)',
			transition: 'transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;',
			color: '#000'
		},
		expandedIcon: {
			transform: 'rotateZ(270deg)'
		},
		flex: {
			flex: 1
		},
		task: {
			backgroundColor: '#F8F8F8',
			padding: [[ 4, 0, 20, 0 ]],
			borderBottom: '1px solid #E3E3E3',
		},
		isToday: {
			backgroundColor: '#DFEEE7'
		}
	}) );


export default function Tasks ({ tasks = [] })
{
	const classes = useStyles(),
		[ expanded, setExpanded ] = useState ( false ),
		handleChange = panel => ( event, newExpanded ) => setExpanded ( newExpanded ? panel : false );

	return (
		<div className={ classes.root }>
			{ tasks.map ( ( { id, name, completed, rows }, i ) =>
				<div
					key={ id }
					className={ classes.task }
				>
					<Accordion
						square
						expanded={ expanded === id }
						onChange={ handleChange ( id ) }
					>
						<AccordionSummary>
							<div className={ classes.header }>
								<div className={ classes.section }>
									<Typography>
										<b>{ i + 1 }.</b>
									</Typography>
								</div>
								{ completed &&
									<img className={ classes.completed } src="completed.svg" alt="" />
								}
								<Typography className={ classes.flex }>{ name }</Typography>
								<div className={ classes.section }>
									{ !!rows[ 1 ] &&
										<IconButton className={ classes.button } onClick={ handleChange ( id ) }>
											<FigmaIcon
												className={ clsx ( classes.icon, {
													[ classes.expandedIcon ]: expanded === id
												} ) }
												name="chevronLeft"
											/>
										</IconButton>
									}
								</div>
							</div>
						</AccordionSummary>
						{ !!rows[ 1 ] &&
						<AccordionDetails>
							{ rows.map ( ( row, i ) =>
								<TaskRow key={ row.timestamp + '-' + i } { ...row } />
							) }
						</AccordionDetails>
						}
					</Accordion>
					{
						expanded !== id &&
						<TaskRow
							{ ...rows[ 0 ] }
							expanded={ expanded === id }
							first
						/>
					}
				</div>
			) }
		</div>
	);
}
