import React, { useCallback } from 'react';
import { Grid } from '@material-ui/core';
import { Points, Table, Managers, PhotoModal, Calendar } from 'containers';
import { MainComponent, MainHeader, MainContent, MainDrawer, HeaderLogo, HeaderProfile } from 'mlk-ui-kit';
import { RangeSwitch, HeaderProfileBody } from 'components';
import { useDispatch } from 'react-redux';
import actions from 'actions';


export default React.memo ( Main );

function Main ()
{
	const dispatch = useDispatch(),
		reset = useCallback (
			() => dispatch ( actions.reset() ),
			[ dispatch ]
		);

	return (
		<MainComponent>
			<MainHeader>
				<Grid container alignItems="center">
					<HeaderLogo onClick={ reset }>MLK MAPS</HeaderLogo>
					<RangeSwitch />
					<Calendar />
				</Grid>

				<HeaderProfile>
					<HeaderProfileBody />
				</HeaderProfile>
			</MainHeader>

			<MainContent>
				<Points />
				<Table />
			</MainContent>

			<MainDrawer>
				<Managers />
			</MainDrawer>

			<PhotoModal />
		</MainComponent>
	);
}
