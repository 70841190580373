import { Managers } from 'components';
import { connect } from 'react-redux';
import actions from 'actions';


export default connect (
	({ table, managers }) => ({
		...managers,
		isRange: table && table.isRange,
		tableType: table && table.tableType
	}),
	dispatch => ({
		getGroups: () => dispatch ( actions.getGroups() ),
		selectManagers: ( groupId, id, all, excludeBase, preventAutoZoom ) => dispatch (
			actions.selectManagers ( groupId, id, all, excludeBase, preventAutoZoom )
		),
		logout: () => dispatch ( actions.logout() )
	})
)( Managers );
