import { Panel } from 'components';
import { connect } from 'react-redux';
import actions from 'actions';


export default connect (
	({ panel, points: { date, range } }) => ({ ...panel, date, range }),
	dispatch => ({
		closePanel: () => dispatch ( actions.closePanel() )
	})
)( Panel );
