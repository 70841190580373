import './waiter.css';
import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
const useStyles = makeStyles ( theme => ({
	container: {
		position: 'absolute',
		left: 0,
		right: 0,
		top: 0,
		bottom: 0,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		backgroundColor: props => props.overlay ? 'rgba(255,255,255,0.7)' : 'rgba(255,255,255,0.2)',
		zIndex: 1
	},
	waiter: {
		color: props => props.htmlColor || theme.palette.primary.main
	}
}) );


export default React.memo ( Waiter );

function Waiter ({ className, htmlColor, show, overlay })
{
	const classes = useStyles ({ htmlColor, overlay });

	return (
		<div className={ clsx ( classes.container, { hidden: !show }, className ) }>
			{ !overlay &&
				<progress className={ clsx ( 'pure-material-progress-circular', classes.waiter ) } />
			}
		</div>
	);
}
