import { all, debounce, put, select, takeLatest } from 'redux-saga/effects';
import * as actionTypes from 'actionTypes';
import actions from 'actions';
import api from 'api';


export default function* root ()
{
	yield all ([
		takeLatest ( actionTypes.INIT_FILTERS_REQUEST, initFiltersRequest ),
		takeLatest ( actionTypes.SET_FILTERS, setFiltersIndicator ),
		debounce (
			500,
			[ actionTypes.POINTS_SET_DATE, actionTypes.SELECT_MANAGERS ],
			reinitFilters
		),
		takeLatest ( actionTypes.RESET, reset )
	]);
}

export function* initFiltersRequest ({ payload: waiter })
{
	const { points: { date, range }, managers: { ids } } = yield select(),
		filters = yield api.web.getFilters ( date, range, ids );

	if ( filters.regions ) filters.regions = filters.regions.map ( ({ value }) => ({ label: value, value }) );

	if ( filters.error ) yield put ( actions.error ( filters.error, true ) );

	yield put ( actions.initFilters ( filters, waiter ) );
}

export function* setFiltersIndicator ()
{
	const { filters: { values, indicator } } = yield select();

	let newIndicator = values.visited !== null;

	Object.keys ( values ).forEach (
		name => values[ name ] && ( Array.isArray ( values[ name ] ) ? values[ name ].length : true ) && ( newIndicator = true )
	);

	if ( indicator !== newIndicator )
	{
		yield put (
			actions.setFiltersIndicator ( newIndicator )
		);
	}
}

export function* reinitFilters ()
{
	yield put ( actions.initFiltersRequest() );
}

export function* reset ()
{
	yield put ( actions.resetFilters() );
}
