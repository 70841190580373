import React, { useState } from 'react';
import clsx from 'clsx';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import MuiExpansionPanel from '@material-ui/core/Accordion';
import MuiExpansionPanelSummary from '@material-ui/core/AccordionSummary';
import MuiExpansionPanelDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import { FigmaIcon, PhotoStepper } from 'components';
import moment from 'moment';
const ExpansionPanel = withStyles ({
		root: {
			backgroundColor: 'transparent',
			borderLeft: 'none',
			borderRight: 'none',
			boxShadow: 'none',
			padding: 0,
			'&:not(:last-child)': {
				borderBottom: '1px solid #E3E3E3'
			},
			'&:before': {
				display: 'none'
			},
			'&$expanded': {
				margin: 0
			}
		},
		expanded: {}
	})( MuiExpansionPanel ),

	ExpansionPanelSummary = withStyles ({
		root: {
			border: 0,
			padding: [[ 0, 8, 0, 18 ]],
			height: 36,
			minHeight: 0,
			'&$expanded': {
				minHeight: 0
			}
		},
		content: {
			justifyContent: 'space-between',
			alignItems: 'center',
			margin: 0,
			'&$expanded': {
				margin: 0
			}
		},
		expanded: {
			minHeight: 0
		}
	})( MuiExpansionPanelSummary ),

	ExpansionPanelDetails = withStyles ( theme => ({
		root: {
			padding: 0,
			display: 'block'
		}
	}))( MuiExpansionPanelDetails ),

	useStyles = makeStyles ( theme => ({
		root: {
			display: 'flex',
			flexDirection: 'column',
			flex: 1,
			overflowY: 'scroll'
		},
		bold: {
			fontWeight: 'bold'
		},
		button: {
			width: 28,
			height: 28,
			padding: 0
		},
		icon: {
			fontSize: 12,
			transform: 'rotateZ(180deg)',
			transition: 'transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;',
			color: '#000'
		},
		expandedIcon: {
			transform: 'rotateZ(270deg)'
		}
	}) );


export default function PhotosBase ({ photo = {} })
{
	const classes = useStyles(),
		[ expanded, setExpanded ] = useState ( false ),
		handleChange = panel => ( event, newExpanded ) => setExpanded ( newExpanded ? panel : false );

	return Object.keys ( photo ).map ( date =>
		<ExpansionPanel
			key={ date }
			square
			expanded={ expanded === date }
			onChange={ handleChange ( date ) }
			// TransitionProps={{ unmountOnExit: true }}
		>
			<ExpansionPanelSummary>
				<Typography className={ clsx ({ [ classes.bold ]: expanded === date }) }>
					{ moment ( date ).format ( 'DD MMM YYYY' ) }
				</Typography>
				<IconButton className={ classes.button } onClick={ handleChange ( date ) }>
					<FigmaIcon
						className={ clsx ( classes.icon, {
							[ classes.expandedIcon ]: expanded === date
						} ) }
						name="chevronLeft"
					/>
				</IconButton>
			</ExpansionPanelSummary>
			<ExpansionPanelDetails>
				<PhotoStepper steps={ photo[ date ] } active={ expanded === date } />
			</ExpansionPanelDetails>
		</ExpansionPanel>
	);
}
