import React, { useCallback } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import MuiTooltip from '@material-ui/core/Tooltip';
import { DotButton } from 'components';
import { getColorById } from 'functions';
import { SelectTable } from 'context';
import { useDispatch, useSelector } from 'react-redux';
import { useRequestTableData } from 'hooks';
import actions from 'actions';
import IconButton from '@material-ui/core/IconButton';
import { FigmaIcon } from 'components';

const Tooltip = withStyles ( theme => ({
		tooltip: {
			fontSize: 14,
			backgroundColor: theme.palette.primary.main,
			maxWidth: 180,
			borderRadius: 5,
			padding: [[ 10, 16 ]]
		}
	}) )( MuiTooltip ),
	prevent = e => {
		e.stopPropagation();
		e.preventDefault();
	},
	EmptyComponent = ({ children }) => children;


export default React.memo ( Manager );

function Manager ({ isRange, tableType, name, groupId, id, checked, disabled, data, visited, route, description, showIncludeBase, includeBase, selectManagers})
{
	const dispatch = useDispatch(),
		dotButtonIsDisabled = disabled || !data || !route,
		lastSelected = useSelector ( state => lastSelectedCheck ( state, groupId, id) ),
		selected = useSelector ( state => isSelected ( state, groupId, id) ),
		isOpenedTable = useSelector ( state => isOpenTable( state ) ),
		isGroup = useSelector ( state => state.table.isGroup ),
		requestData = useRequestTableData(),
		classes = useStyles ({
			disabled,
			dotButtonIsDisabled,
			showDotButton: id !== 'all',
			id
		}),
		onChange = useCallback (
			() => selectManagers ( groupId, id ),
			[ selectManagers, groupId, id ]
		),
		onClick = useCallback (
			() => selectManagers ( false, false, disabled, includeBase, true ),
			[selectManagers, disabled, includeBase]
		),

		setLastSelected = useCallback (
			() => {
				dispatch (
					actions.setLastSelected ({ id, groupId })
				);
			},
			[ groupId, id, dispatch ]
		),

		openTable = useCallback (
			e => {
				dispatch (
					actions.tableRequest ({ ...requestData, id, groupId, tableType })
				);
				dispatch(actions.clearLastSelected());

				e.preventDefault(); // prevent safari bug (extra selectManagers call)
			},
			[ id, groupId, tableType, requestData, dispatch ]
		);

	const DescriptionTooltip = description ? Tooltip : EmptyComponent;

	return (
		<React.Fragment>
			<FormControlLabel
				className={ classes.formControlLabel }
				control={
					<Checkbox
						color="primary"
						{...{ checked, onChange }}
					/>
				}
				label={
					<div className={ classes.label }>
						<div className={ classes.labelContainer }>
							{ id !== 'all' &&
								<div className={ classes.circle } />
							}
							{/*<div className={ classes.nameContainer }>
								<p className={ classes.name }>*/}{ /** name */ }{/*</p>
							</div>*/}
							<div>{ name }</div>
							{ data &&
								<span className={ classes.routes }>
									&nbsp;(<b className={ disabled ? '' : classes.visited }>{ visited || 0 }</b>/<b>{ route || 0 }</b>)
								</span>
							}
						</div>
						<DescriptionTooltip title={ description } placement="left">
							<div className={ classes.dotButtonWrapper } onClick={ prevent }>
								<SelectTable.Consumer>
									{ openSelectTableDialog =>
										(!isGroup && isOpenedTable && lastSelected) ? <IconButton
											className={ classes.iconButton }
											color="primary"
											disabled={ dotButtonIsDisabled }
											onClick={(e) => {
												setLastSelected();
												return isRange ? openSelectTableDialog ({ ...requestData, id, groupId }) : openTable(e);
												}
											}
										>
											<FigmaIcon name='refresh'  className={ classes.refreshIcon } />
										</IconButton>
										:
										<DotButton
											onClick={ (e) => {
												!selected && onChange();
												setLastSelected();
												return isRange ? openSelectTableDialog ({ ...requestData, id, groupId }) : openTable(e);
											}
												
											}
											disabled={ dotButtonIsDisabled }
										/>
										
									}
								</SelectTable.Consumer>
							</div>
						</DescriptionTooltip>
					</div>
				}
			/>
			{ showIncludeBase && !isRange && tableType !== 'summary' &&
				<Button
					className={ classes.button }
					size="small"
					color="primary"
					{...{ onClick }}
				>
					<Typography>{ includeBase ? 'Отключить базу' : 'Включить базу' }</Typography>
				</Button>
			}
		</React.Fragment>
	);
}


const useStyles = makeStyles ( theme => ({
	formControlLabel: {
		margin: [[ -6, 0, -6, 6 ]],
		height: 42
	},
	label: {
		width: 240, // name...
		display: 'flex',
		alignItems: 'center',
		lineHeight: 1.1,
		color: ({ disabled }) => disabled ? '#bdbdbd' : '#000'
	},
	labelContainer: {
		flex: 1,
		display: 'flex',
		alignItems: 'center'
	},
	circle: {
		width: 10,
		height: 10,
		borderRadius: 5,
		marginRight: 5,
		flexShrink: 0,
		backgroundColor: ({ disabled, id }) => id === 'all' || disabled ? 'rgba(0, 0, 0, 0.30)' : getColorById ( id )
	},
	// name...
	// nameContainer: {
	// 	flexBasis: '100%',
	// 	minWidth: 0
	// },
	// name: {
	// 	textOverflow: 'ellipsis',
	// 	whiteSpace: 'nowrap',
	// 	overflow: 'hidden'
	// },
	visited: {
		color: /* ({ disabled }) =>  disabled ? theme.palette.action.disabled : */ '#129C8B'
	},
	dotButtonWrapper: {
		margin: [[ -3, 0, -3, 'auto' ]],
		cursor: ({ dotButtonIsDisabled }) => dotButtonIsDisabled ? 'auto' : 'pointer'
	},
	button: {
		margin: [[ 3, 0, 6, 0 ]],
		alignSelf: 'flex-start',
		padding: [[ 4, 32 ]]
	},
	routes: {
		color: ({ disabled }) => /* disabled ? theme.palette.action.disabled : */ 'inherit'
	},
	refreshIcon: {
		height: 16,
		width: 16
	},
}) );

function lastSelectedCheck({ managers }, groupId, id)
{
	return managers.lastSelected ? (managers.lastSelected.id === id) && (managers.lastSelected.groupId === groupId) : false
}

function isSelected({ managers }, groupId, id)
{
	return managers.selected[groupId][id];
}

function isOpenTable({ table })
{
	return !!table && !table.closed;
}
