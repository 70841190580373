import 'react-day-picker/lib/style.css';
import "moment/locale/ru";
import moment from 'moment';
import clsx from 'clsx';
import React, { useState, useCallback, useMemo, useEffect } from 'react';
import DayPicker from 'react-day-picker';
import localeUtils from 'react-day-picker/moment';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import { FigmaIcon } from 'components';
import { makeStyles } from '@material-ui/core/styles';
import { useSetState } from 'react-use';

moment.locale ( 'ru' );

const inititalState = {
		from: null,
		to: null,
		enteredTo: null // Keep track of the last day for mouseEnter
	},
	baseModifiers = {
		firstOfMonth: day => day.getDate() === 1,
		lastOfMonth: day => new Date ( day.getTime() + 86400000 ).getDate() === 1,
		firstOfWeek: { daysOfWeek: [ 1 ] },
		lastOfWeek: { daysOfWeek: [ 0 ] }
	},
	Empty = () => null;


export default React.memo ( Calendar );

function Calendar ({ isRange, initialDate, onChange, minDate, maxDate })
{
	const classes = useStyles(),
		today = moment().endOf ( 'day' ).toDate(),
		todayPlusWeek = moment().endOf ( 'day' ).add(30, 'days').toDate(),
		[ selectedDay, setSelectedDay ] = useState ( initialDate ),
		[ state, setState ] = useSetState ( inititalState ),
		handleResetClick = useCallback (
			() => setState ({ ...inititalState }),
			[ setState ]
		),
		onDayClick = useCallback (
			( day, { disabled } ) => {
				if ( disabled ) return;
				else if ( !isRange )
				{
					setSelectedDay ( day );

					return;
				}

				const { from, to } = state;

				if ( from && to && day >= from && day <= to )
				{
				  handleResetClick();

				  return;
				}

				if ( isSelectingFirstDay ( from, to ) )
				{
					setState ({
						from: day,
						to: null,
						enteredTo: null,
					});
				}
				else
				{
					const invert = from > day,
						newFrom = invert ? day : from,
						newTo = invert ? from : day;

					setState ({
						from: newFrom,
						to: newTo,
						enteredTo: newTo,
					});
				}
			},
			[ isRange, setSelectedDay, state, setState, handleResetClick ]
		),
		handleDayMouseEnter = useCallback (
			( day, { disabled } ) => {
				if ( disabled ) return;

				const { from, to } = state;

				if ( !isSelectingFirstDay ( from, to ) )
				{
					setState ({ enteredTo: day });
				}
			},
			[ state, setState ]
		),

		// Modifiers
		{ from, to, enteredTo } = state,
		invert = enteredTo && from > enteredTo,
		modifiers = useMemo (
			() => {
				let start, end;

				if ( isRange )
				{
					start = invert ? enteredTo : from;
					end = ( invert ? from : enteredTo ) || start;
				}
				else
				{
					start = selectedDay;
					end = selectedDay;
				}

				return {
					...baseModifiers,
					start,
					end,
					max: todayPlusWeek,
					min: minDate,
					today
				};
			},
			[ isRange, selectedDay, today, todayPlusWeek, invert, from, enteredTo, minDate ]
		),
		disabledDays = useMemo (
			() => ({
				before: minDate,
				after: todayPlusWeek
			}),
			[ minDate, todayPlusWeek ]
		),
		selectedDays = useMemo (
			() => [
				from,
				invert ? { from: enteredTo, to: from } : { from, to: enteredTo }
			],
			[ invert, from, enteredTo ]
		);

	useEffect (
		() => {
			onChange ( isRange ? { from, to } :  selectedDay );
		},
		[ selectedDay, from, to, isRange, onChange ]
	);

	// reset if main props changed
	useEffect (
		() => {
			if ( isRange )
			{
				setState ({
					...inititalState,
					...initialDate,
					enteredTo: ( initialDate || {} ).to
				});
			}
			else setSelectedDay ( initialDate );
		},
		[ isRange, initialDate, minDate, maxDate, setState ]
	);

	// Elements
	const navbarElement = useCallback (
			({ month, prevMonth, nextMonth, showPreviousButton, showNextButton, onPreviousClick, onNextClick, ...other }) => (
				<div className="DayPicker-NavBar">
					<IconButton className="DayPicker-NavButton--prev" onClick={ e => onPreviousClick() } disabled={ !showPreviousButton }>
						<FigmaIcon name="chevronLeft" fontSize="inherit" />
					</IconButton>
					<span>{ moment ( month ).format ( 'MMMM Y' ) }</span>
					<IconButton className="DayPicker-NavButton--next" onClick={ e => onNextClick() } disabled={ !showNextButton }>
						<FigmaIcon name="chevronRight" fontSize="inherit" />
					</IconButton>
				</div>
			),
			[]
		),
		renderDay = useCallback (
			( day, { today, start, end, disabled } ) => (
				<div className="DayPicker-Day-Body">
					<IconButton
						className={ clsx ( classes.dayButton, {
							[ classes.dayButtonToday ]: today,
							[ classes.dayButtonActive ]: ( start || end ) && !disabled
						} ) }
						color={ ( start || end ) && !disabled ? 'primary' : 'default' }
						{...{ disabled }}
					>
						{ day.getDate() }
					</IconButton>
				</div>
			),
			[ classes ]
		);

	// Header years
	const year = getYear ( isRange ? from : selectedDay ),
		year2 = isRange ? ( to ? getYear ( to ) : year ) : year;

	return (
		<div>
			<Toolbar className={ classes.header }>
				<span className={ classes.year }>{ year === year2 ? year : `${ year } - ${ year2 }` }</span>
				<span>
					{ isRange ?
						`${ getLabel ( from ) } ${ from && to ? '-' : '' } ${ getLabel ( to ) }`
						:
						getLabel ( selectedDay )
					}
				</span>
			</Toolbar>
			<DayPicker
				className={ classes.DayPicker }
				locale="ru"
				firstDayOfWeek={ 1 }
				toMonth={ maxDate < today ? maxDate : todayPlusWeek }
				onDayMouseEnter={ isRange ? handleDayMouseEnter : undefined }
				selectedDays={ isRange ? selectedDays : selectedDay }
				captionElement={ Empty }
				{...{ onDayClick, modifiers, navbarElement, renderDay, disabledDays, localeUtils }}
			/>
			{/* <pre>
				{ JSON.stringify ( { selectedDay, state, modifiers, disabledDays, selectedDays }, null, 4 ) }
			</pre> */}
		</div>
	);
}


const min = {
		borderTopLeftRadius: '50%',
		borderBottomLeftRadius: '50%'
	},
	max = {
		borderTopRightRadius: '50%',
		borderBottomRightRadius: '50%'
	},
	navButton = {
		backgroundImage: 'none',
		fontSize: 12,
		height: 44,
		width: 44
	},
	useStyles = makeStyles ( theme => ({
		header: {
			display: 'flex',
			flexDirection: 'column',
			justifyContent: 'space-between',
			alignItems: 'flex-start',
			height: 100,
			backgroundColor: theme.palette.primary.main,
			padding: 24,
			fontSize: 20,
			userSelect: 'none'
		},
		year: {
			fontSize: 14
		},
		DayPicker: {
			'& *': {
				outline: 'none'
			},
			'& .DayPicker-wrapper': {
				padding: 0
			},
			'& .DayPicker-Months': {
				minHeight: 268
			},
			'& .DayPicker-Month': {
				marginTop: 4
			},
			'& .DayPicker-NavBar': {
				display: 'flex',
				justifyContent: 'space-between',
				alignItems: 'center',
				fontSize: 14,
				textAlign: 'center',
				marginTop: 8
			},
			'& .DayPicker-NavButton--prev': navButton,
			'& .DayPicker-NavButton--next': navButton,
			'& .DayPicker-Weekday': {
				fontSize: 10
			},
			'& .DayPicker-Day': {
				borderRadius: 0,
				padding: 0,
				backgroundColor: 'transparent !important',
				overflow: 'hidden',
				'&.DayPicker-Day--min .DayPicker-Day-Body': min,
				'&.DayPicker-Day--firstOfMonth .DayPicker-Day-Body': min,
				'&.DayPicker-Day--firstOfWeek .DayPicker-Day-Body': min,
				'&.DayPicker-Day--start .DayPicker-Day-Body': min,
				'&.DayPicker-Day--max .DayPicker-Day-Body': max,
				'&.DayPicker-Day--lastOfMonth .DayPicker-Day-Body': max,
				'&.DayPicker-Day--lastOfWeek .DayPicker-Day-Body': max,
				'&.DayPicker-Day--end .DayPicker-Day-Body': max,
				'& .DayPicker-Day-Body': {
					margin: [[ 1, 0 ]],
					padding: 1
				},
				'&.DayPicker-Day--selected': {
					'& .DayPicker-Day-Body': {
						backgroundColor: 'rgb(34, 176, 224, 0.2)'
					},
				}
			}
		},
		dayButton: {
			width: 36,
			height: 36,
			fontSize: 12,
			color: '#000',
			padding: 6
		},
		dayButtonToday: {
			color: theme.palette.primary.main
		},
		dayButtonActive: {
			backgroundColor: theme.palette.primary.main,
			color: 'white'
		}
	}) );


function isSelectingFirstDay ( from, to )
{
	const isRangeSelected = from && to;
	return !from || isRangeSelected;
}

function getLabel ( date )
{
	if ( !date ) return '';

	return moment ( date ).format ( 'dd, MMM D' );
}

function getYear ( date )
{
	if ( !date ) date = new Date();

	return moment ( date ).format ( 'YYYY' );
}
