import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
const useStyles = makeStyles ({
	row: {
		padding: [[ 0, 16, 0, 16 ]],
	},
	title: {
		margin: [[ 6, 10, 4, 10 ]]
	},
	content: {
		display: 'flex',
		flexWrap: 'wrap',
		flex: 1
	}
});


export default React.memo ( PhotoRow );

function PhotoRow ({ title, children })
{
	const classes = useStyles();

	return (
		<div className={ classes.row }>
			<Typography className={ classes.title }>{ title }</Typography>
			<div className={ classes.content }>
				{ children }
			</div>
		</div>
	);
}
