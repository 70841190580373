import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PhotoRow from './PhotoRow';
import PhotoTile from './PhotoTile';
import { Refresh, Waiter } from 'components';
import { useRequest, usePhotoLoader } from 'hooks';
const useStyles = makeStyles ({
	root: {
		flex: 1,
		minHeight: 200,
		position: 'relative'
	},
	header: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		padding: [[ 8, 26 ]]
	},
	results: {
		fontSize: 12
	},
	row: {
		padding: [[ 0, 16, 0, 16 ]],
	},
	brand: {
		margin: [[ 6, 10, 4, 10 ]]
	},
	content: {
		display: 'flex',
		flexWrap: 'wrap',
		flex: 1
	},
	block: {
		width: 52,
		height: 70,
		margin: [[ 6, 10 ]]
	},
	label: {
		fontSize: 12,
		color: '#959595',
		textAlign: 'center',
		marginTop: 4
	}
});


export default React.memo ( PhotoByTasks );

function PhotoByTasks ({ active, pointID, managerID, date, range })
{
	const classes = useStyles(),
		[ ids, setIds ] = useState ( [] ),
		[ { tasks = [], error = '' }, waiter, refresh ] = useRequest ( 'getPhotoByTasks', [ pointID, managerID, date, range ] ),
		[ photoWaiter, photoFiles ] = usePhotoLoader ( ids );

	useEffect (
		() => {
			setIds (
				tasks.reduce (
					( ids, task = {} ) => ids.concat (
						( task.photo || [] ).map ( p => p.photoID )
					),
					[]
				)
			);
		},
		[ tasks ]
	);

	return (
		<div className={ classes.root }>
			{ error ?
				( waiter ? null : <Refresh onClick={ refresh } {...{ error }} /> )
				:
				tasks.map ( ({ taskID, name, photo }) => (
					<PhotoRow key={ taskID } title={ name }>
						{ photo.map ( ({ photoID, date }) =>
							<PhotoTile
								key={ photoID }
								src={ ( photoFiles[ photoID ] || {} ).url }
								{...{ date }}
							/>
						) }
					</PhotoRow>
				) )
			}
			<Waiter show={ photoWaiter || waiter } />
		</div>
	);
}
