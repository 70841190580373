import { all, debounce, takeLatest, put, select } from 'redux-saga/effects';
import * as actionTypes from 'actionTypes';
import actions from 'actions';
import api from 'api';
import { prepareFilters } from 'functions';
import { isManagerAllPoints } from 'functions';


export default function* root ()
{
	yield all ([
		debounce (
			500,
			[
				actionTypes.SELECT_MANAGERS,
				actionTypes.POINTS_SET_DATE,
				actionTypes.GET_GROUPS,
				actionTypes.REQUEST_POINTS_BACKGROUND,
				actionTypes.UPDATED_FILTERS,
				actionTypes.TABLE_CLOSE
			],
			getPoints
		),
		takeLatest ( [ actionTypes.TABLE_SET_DATE, actionTypes.TABLE_REQUEST ], syncDate )
	]);
}

export function* getPoints ({ payload: { background } = {} })
{
	const waiter = + new Date();

	if ( !background ) yield put ( actions.pointsRequest ( waiter ) );

	const { managers: { ids, includeBase }, points: { date, range }, filters: { values }, panel, table } = yield select(),
		filters = prepareFilters ( values ),
		managers = table.id ? [table.id] : ids,
		managerAllPoints = isManagerAllPoints ( managers, range ),
		{ points = [], base = [], managersMap = {}, error } = yield api.web.getPoints (
			date,
			range,
			managers,
			!background && includeBase,
			filters,
			managerAllPoints
		);

	// Закрываем панель, если соответствующей точки нет на карте
	if ( panel )
	{
		const { id, managerID } = panel;

		if ( ids.indexOf ( managerID ) === -1 || !points.concat ( base ).find ( point => id === point.id ) )
		{
			yield put ( actions.closePanel() )
		}
	}

	if ( error ) yield put ( actions.error ( error ) );

	yield put (	background ?
		actions.pointsBackground ( points, managersMap ) :
		actions.points ( points, base, managersMap, waiter )
	);
}

export function* syncDate ({ payload: { date } })
{
	const normalizedDate = date instanceof Date ? date : date.to;
	const normalizedDates = date instanceof Date ? date : [date.from, date.to]

	yield put ( actions.pointsSetDate ( normalizedDate, normalizedDates ) );
}
